<template>
  <a-tooltip
    v-if="
      (!data.contest && !isDemo && data.mt5_account_data.status !== 'blocked') ||
      (!isDemo && data.contest?.status == 'approved')
    "
    placement="top"
    :title="i18n.t(`${isPublic ? 'unpublish' : 'publish'}.modal.title`)"
  >
    <sdButton
      size="default"
      @click="openPublishModal({ id: data.id, type: isPublic ? 'unpublish' : 'publish' })"
      outlined
      type="secondary"
      :disabled="data.mt5_account_data.status === 'closed'"
    >
      <sdFeatherIcons :type="isPublic ? 'wifi-off' : 'wifi'" />
    </sdButton>
  </a-tooltip>

  <a-tooltip
    v-if="!data.contest || data.contest.status === 'approved' || !isDemo"
    placement="top"
    :title="i18n.t('accounts.deposit')"
  >
    <sdButton
      size="default"
      @click="openModal({ id: data.id, type: 'deposit' })"
      :disabled="data.mt5_account_data.status === 'closed'"
      outlined
      type="success"
    >
      <sdFeatherIcons type="download" />
    </sdButton>
  </a-tooltip>

  <a-tooltip v-else placement="top" :title="i18n.t('accounts.trading.close')">
    <sdButton
      size="default"
      type="danger"
      :disabled="data.mt5_account_data.status !== 'active'"
      @click="openCloseModal"
      outlined
    >
      <sdFeatherIcons type="x" />
    </sdButton>
  </a-tooltip>

  <a-tooltip v-if="!isDemo" placement="top" :title="i18n.t('accounts.withdrawal')">
    <sdButton
      size="default"
      @click="openModal({ id: data.id, type: 'withdrawal' })"
      :disabled="data.mt5_account_data.status === 'closed'"
      outlined
      type="primary"
    >
      <sdFeatherIcons type="upload" />
    </sdButton>
  </a-tooltip>

  <a-tooltip v-if="data.contest && data.contest.status === 'active'" placement="top" :title="i18n.t('accounts.check')">
    <sdButton size="default" @click="openModalCheck({ id: data.contest.member_id })" type="success" outlined>
      <sdFeatherIcons type="check-square" />
    </sdButton>
  </a-tooltip>
</template>

<script>
import { reactive } from '@vue/reactivity';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
// import { useRoute } from 'vue-router';
export default {
  emits: ['openModal', 'openPublishModal', 'openModalCheck', 'update:closingModal'],
  props: {
    record: {
      type: Object,
      default() {
        return null;
      },
    },
    accountData: {
      type: Object,
      default() {
        return {};
      },
    },
    closingModal: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const i18n = reactive(useI18n());
    // const route = useRoute();

    //FIXME зачем это разделение данных. Из-за этого по разному отрабатывает на странице и в списке.
    const data = computed(() => {
      // console.log(props.record);
      // console.log(props.accountData);
      if (props.record) {
        return props.record;
      } else if (props.accountData) {
        // const { accountData } = props;
        // const record = {
        //   id: accountData.id,
        //   publish: accountData?.attributes.mt5_account_data.publish,
        //   contest: !!accountData?.attributes.contest,
        //   kind: accountData?.attributes.mt5_account_data.kind,
        //   status: accountData?.attributes.mt5_account_data.status,
        //   modificator: accountData?.attributes.mt5_account_data.modificator,
        //   name: accountData?.attributes.mt5_account_data.name,
        // };
        // return record;
        return props.accountData.attributes;
      }
      return { a: 1, b: 2, c: 3 };
    });

    // const isDemo = computed(() => route.params.type === 'demo' || data.value.mt5_account_data.kind === 'demo');
    const isDemo = computed(() => data.value.mt5_account_data.kind === 'demo');

    const isPublic = computed(() => data.value.modificator === 'published');

    const openCloseModal = () => {
      emit('update:closingModal', { visible: true, data: { name: data.value.name, id: data.value.id } });
    };
    const openModal = (data) => {
      emit('openModal', data);
    };

    const openPublishModal = (data) => {
      emit('openPublishModal', data);
    };
    const openModalCheck = (data) => {
      emit('openModalCheck', data);
    };

    return { i18n, data, isDemo, isPublic, openCloseModal, openModal, openPublishModal, openModalCheck };
  },
};
</script>

<style></style>
